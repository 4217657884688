
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useCooperations,
  useDeleteCooperation,
  useSolveCooperation,
  useArchiveCooperation
} from '@/composables/api';
import { CooperationIdOptions } from '@/services/api';

import { CooperationStatus } from '@/interfaces/Cooperation';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Opening',
        value: CooperationStatus.OPENING
      },
      {
        label: 'Solved',
        value: CooperationStatus.SOLVED
      }
    ]
  },
  {
    type: FilterType.CHECKBOX,
    label: 'Is Archived'
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const status = ref();
    const archived = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();

    const { data, isLoading, isFetching, refetch } = useCooperations({ page, keyword, status, archived, sortBy, descSortBy });
    const route = useRoute();
    const { isLoading: isDeletedLoading, mutate } = useDeleteCooperation();
    const { isLoading: isSolvedLoading, mutate: solveMutate } =
      useSolveCooperation();
    const { isLoading: isArchivedLoading, mutate: archiveMutate } =
      useArchiveCooperation();
    const statusIndex = ref(undefined);
    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
      archived.value = event[1] ? '1' : '0';
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const deleteCooperation = ({ cooperationId }: CooperationIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { cooperationId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const solveCooperation = ({ cooperationId }: CooperationIdOptions, index: Number) => {
      statusIndex.value = index;
      solveMutate(
        { cooperationId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const archiveCooperation = ({ cooperationId }: CooperationIdOptions, index: Number) => {
      statusIndex.value = index;
      archiveMutate(
        { cooperationId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Enable successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      data,
      CooperationStatus,
      isLoading,
      isFetching,
      isDeletedLoading,
      sortChange,
      deleteCooperation,
      isSolvedLoading,
      solveCooperation,
      isArchivedLoading,
      archiveCooperation,
      statusIndex,
      searchKeyword,
      FILTER_OPTIONS,
      t,
      handleFilterChange
    };
  }
});
